@media only screen and (max-width: 450px) {
	.bannerImg {
		padding: 0.75rem 0rem 0.75rem 0rem;
	}
}

@media (min-width: 451px) {
	.bannerImg {
		padding: 0.75rem 1rem 0.75rem 1rem;
	}
}

.sectionHeaderContainer {
	width: 100%;
	max-width: 50rem;
	display: flex;
	flex-direction: column;
	background-color: #152e4e;
	font-family: 'Avenir Next';
	color: #fff;
}

.titleOne {
	font-family: 'BenchNine', sans-serif;
	text-transform: uppercase;
	text-align: left;
	color: #27aee8;
	font-size: 1.3em;
}

.titleTwo {
	padding: 0.75rem 1rem 0.75rem 1rem;
	font-family: 'Libre Franklin', sans-serif;
	font-size: 2rem;
	text-transform: uppercase;
	color: #ffffff;
	z-index: 1;
	line-height: 1em;
	font-weight: 900;
	font-style: italic;
	margin-bottom: 0.5rem;
}

.bannerImg img {
	max-width: 20rem;
}
