@media only screen and (max-width: 450px) {
	.icons {
		font-size: 2rem;
		align-self: center;
		color: white;
	}
}

@media (min-width: 451px) {
	.icons {
		font-size: 3.5rem;
		align-self: center;
		color: white;
	}
}

.sectionContainer {
	height: 100vh;
	width: 100%;
	max-width: 50rem;
	scroll-snap-align: start;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #152e4e;
	padding: 3rem 1.5rem 3rem 1.5rem;
	color: #fff;
}

.contentContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 85%;
}

.statementContainer {
	display: flex;
	flex-direction: column;
	font-family: 'Avenir Next';
	color: #fff;
	width: 90%;
	overflow: auto;
	border-bottom: 1px solid #2a3c57;
	margin-bottom: 1rem;
}

.statusContainer {
	padding: 0rem 0.5rem 1rem 0.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
}

.status {
	font-family: 'Avenir Next';
	font-size: 1.2rem;
	color: #ffffff;
	z-index: 1;
	line-height: 1em;
	font-weight: 500;
}

.postDate {
	font-size: 0.7em;
	font-weight: 600;
	color: #6b7f9c;
	align-self: baseline;
	padding-top: 8px;
}
.statement {
	font-weight: 600;
	color: #6b7f9c;
	font-size: 0.85em;
	padding-top: 0.5em;
	font-size: 0.75em;
}

.additionalInfoContainer {
	padding: 0rem 0.5rem 1rem 0.5rem;
	margin-left: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.contactContainer {
	display: flex;
	flex-direction: column;
	font-family: 'Avenir Next';
	color: #fff;
	width: 90%;
	overflow: auto;
	border-bottom: 1px solid #2a3c57;
	margin-bottom: 1rem;
	margin-top: 1rem;
	padding-top: 3rem;
}

.contactTitleContainer {
	padding: 0rem 0.5rem 1rem 0.5rem;
	align-self: center;
}

.title {
	font-family: 'Avenir Next';
	font-variant: small-caps;
	font-size: 1.6rem;
	color: #ffffff;
	line-height: 1em;
	font-weight: 500;
}

.contactLinksContainer {
	display: flex;
	flex-direction: row;
	align-self: center;
	width: 85%;
	justify-content: space-evenly;
	flex-wrap: wrap;
	padding-top: 1rem;
}

.contactLinksContainer svg {
	color: white;
}

.linkLabel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	padding-bottom: 1rem;
}

.link {
	font-size: small;
	align-self: center;
}
