.sectionContainer {
	height: 100vh;
	width: 100%;
	max-width: 50rem;
	scroll-snap-align: start;
	background-color: #152e4e;
	font-family: 'Avenir Next';
	color: #fff;
}

@media only screen and (max-width: 450px) {
	.nameContainer {
		position: absolute;
		top: 25%;
		left: 15%;
	}
	.bannerContainer {
		width: 100%;
		height: 100%;
		max-width: 50rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		position: relative;
	}
	.name {
		color: #fff;
		font-family: 'Monoton', cursive;
		font-size: 3.5rem;
		letter-spacing: 3px;
	}
	.breadcrumbLinks {
		position: absolute;
		top: 75%;
		left: 5%;
		display: flex;
		flex-direction: row;
		width: 90%;
	}
	.bannerImg {
		height: 100%;
	}

	.imgContainer {
		height: 100%;
	}
}

@media (min-width: 451px) {
	.nameContainer {
		position: absolute;
		top: 10%;
		left: 20%;
	}

	.bannerContainer {
		width: 100%;
		height: 100%;
		max-width: 50rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.name {
		color: #fff;
		font-family: 'Monoton', cursive;
		font-size: 4.5rem;
		letter-spacing: 3px;
	}
	.breadcrumbLinks {
		position: absolute;
		top: 80%;
		left: 15%;
		display: flex;
		flex-direction: row;
		width: 90%;
	}

	.bannerImg {
		height: 100%;
	}

	.imgContainer {
		height: 100%;
	}
}
