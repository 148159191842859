@media only screen and (max-width: 450px) {
	.root {
		flex-direction: column;
		padding: 0.5rem 0.5rem 0.5rem 0.5rem;
	}
	.tabs {
		height: 6rem;
		margin-bottom: 0.5rem;
		width: 95%;
	}
}

@media (min-width: 451px) {
	.tabs {
		align-items: center;
		width: 30%;
	}
}

.sectionContainer {
	height: 100vh;
	width: 100%;
	max-width: 50rem;
	scroll-snap-align: start;

	display: flex;
	flex-direction: column;
	align-items: center;

	background-color: #152e4e;
	padding: 3rem 1.5rem 3rem 1.5rem;
	color: #fff;
}

.tabPanels {
	max-height: 20rem;
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 1rem;
	margin-right: 1rem;
}

.tabPanel {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.root {
	display: flex;
	align-self: flex-start;
	width: 100%;
	height: 80%;
}
