.sectionContainer {
	height: 100vh;
	width: 100%;
	max-width: 50rem;
	scroll-snap-align: start;
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #152e4e;
	padding: 3rem 1.5rem 3rem 1.5rem;
	font-family: 'Avenir Next';
}

.contentContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 85%;
}

.gMapContainer {
	height: 100%;
	width: 90%;
	position: sticky;
}

.infoWindow {
	width: 20vh;
	height: 25vh;
	overflow: none;
}

.infoPicture {
	width: 90%;
	height: 90%;
}

.infoPicture img {
	max-height: 100%;
	max-width: 100%;
}
