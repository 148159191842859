@media only screen and (max-width: 450px) {
	.icons {
		font-size: 2rem;
		align-self: center;
	}
	.titleContainer p {
		font-size: 1rem;
		font-variant: small-caps;
	}
}

@media (min-width: 451px) {
	.titleContainer p {
		font-size: 2rem;
		font-variant: small-caps;
	}
	.icons {
		font-size: 3.5rem;
		align-self: center;
	}
}

.linksContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-left: 0.5rem;
	padding-bottom: 0.5rem;
}

.linkLabel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.titleContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.titleContainer img {
	max-height: 4rem;
}

/* material UI Styles */
.root {
	width: 100%;
	height: 100%;
	box-shadow: none;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 0px;
}

.bullet {
	display: inline-block;
	margin: 0 2px;
	transform: scale(0.8);
}

.title {
	font-variant: small-caps;
	letter-spacing: 2px;
}

.pos {
	margin-bottom: 12;
}

.text {
	padding-left: 0.5rem;
	padding-bottom: 0.5rem;
	font-size: small;
}

.cardContent {
	height: 100%;
	overflow: scroll;
	box-shadow: none;
	padding-left: 2rem;
}

.link {
	font-size: small;
	align-self: center;
}
