* {
	box-sizing: border-box;
}

html,
body {
	height: 100vh;
	width: 100%;
	font-size: calc(14px + 0.5vw);
}

.app {
	scroll-snap-type: y mandatory;
	/* scroll-snap-type: y proximity; */
	scroll-snap-points-y: repeat(100vh);
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	height: 100vh;
	background-color: #152e4e;
}

.appFlex {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
