@media only screen and (max-width: 450px) {
	.icons {
		font-size: 2rem;
		align-self: center;
	}
}

@media (min-width: 451px) {
	.icons {
		font-size: 3.5rem;
		align-self: center;
	}
}

.linkLabel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	padding-bottom: 1rem;
}

.link {
	font-size: small;
	align-self: center;
}
